import React, { useRef } from "react";
import Slider from "react-slick";
import FreatureCard2 from "../../components/FreatureCard2";
import timeline from "../../assets/image/features/segment.png";
import table from "../../assets/image/svg/contents.svg";
import bookmark from "../../assets/image/svg/bookmark.svg";
import share from "../../assets/image/svg/share.svg";
import info from "../../assets/image/svg/info.svg";
import node from "../../assets/image/svg/node.svg";

const items = [
  {
    icon: timeline,
    title: "Segmented Timeline",
    content:
      "Navigation couldn't be easier with our image navigation timeline",
    link: "/info/features/segmented-timeline"
  },
  {
    icon: table,
    title: "Chapter Selection",
    content:
      "Get where you want to quickly with our contents table",
    link: "/info/features/chapter-selection"

  },
  {
    icon: bookmark,
    title: "Bookmarking",
    content:
      "Bookmark your favourite section of a video to save for later alternatively email it to yourself or a friend",
    link: "/info/features/bookmarking"
  },
  {
    icon: node,
    title: "Chapter Overview",
    content:
      "This is the heart of the interactive video. Displaying relevant timely content to increase engagement.",
    link: "/info/features/chapter-overview"

  },  
  {
    icon: share,
    title: "Share capabilities",
    content:
      "Share your video on all social media avenues",
  },
  {
    icon: info,
    title: "Swipe Navigation",
    content:
      "A Digma video can be watched, controlled, navigated and shared all with one hand. Perfect for watcing videos on your daily commute.",
    link: "/info/features/swipe-navigation"      
  },
];

const Features = () => {
  const elSlider = useRef();

  const slickSettings = {
    autoplay: false,
    slidesToShow: 3,
    arrows: false,
    infinite: true,
    touchThreshold: 200,
    dots: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },      
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 468,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };  
  return (
    <div className="pt-20 pb-9 pb-md-13 pb-lg-15 pt-0">
      <div className="container">
        {/* section title */}
        <div className="row justify-content-center">
          <div className="col-xl-5 col-lg-6 col-md-7 col-xs-8">
            <div
              className="text-center mb-7 mb-lg-10"
              data-aos="fade-up"
              data-aos-duration={600}
            >
              <h3 className="font-size-10 letter-spacing-n83">
                Other Features
              </h3>
            </div>
          </div>
        </div>
        {/* End Section title */}
        <div className="row">
            <div className="col-12">
              <div className="testimonial-one">
                <Slider
                  ref={elSlider}
                  {...slickSettings}
                  css={`
                    .slick-dots {
                      display: flex !important;
                    }
                  `}
                >        
                {items.map((item, index) => (
                  <div
                    className="relative"
                    data-aos="fade-up"
                    data-aos-duration={900}
                    key={index}
                  >
                    <FreatureCard2 options={item} />
                  </div>
                ))}
                </Slider>
              </div>
            </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
