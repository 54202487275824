import React from "react";

const Hero = () => {
  return (
    <div className="pt-4 pt-md-6 pt-lg-10">
      <div className="container">
        {/* Section Padding */}
        <div className="row justify-content-center">
          <div className="col-xl-7 col-lg-8 col-md-11">
            <div
              className="text-center mb-11 mb-lg-10"
              data-aos="fade-up"
              data-aos-duration={500}
              data-aos-delay={500}
            >
              <h1 className="font-size-9 mb-5">Get Instant Gratification with our Chapter Selection Feature</h1>
              <h2 className="font-size-7 mb-0">
              Know where you want to go? Get there quicker with our chapter selection feature.
              </h2>
            </div>
          </div>
        </div>
        {/* End Section Padding */}
      </div>
    </div>
  );
};

export default Hero;
