import React from "react";

import imgC from "../../assets/image/inner-page/webp/chapter-selection.webp";
import imgD from "../../assets/image/inner-page/webp/chapter-selection-mobile.webp";

const CTA  = ({ className, ...rest }) => {

  return (
    <div className={className} {...rest}>
      <div className="pt-14 pt-md-19 pt-lg-10 pb-15 pb-md-18 pb-lg-25 mb-lg-1">
      <div className="container">
      <div className="row align-items-center justify-content-center">
          <div
            className="col-xl-6 col-lg-6 col-md-10 col-sm-11"
            data-aos="fade-right"
            data-aos-duration={500}
          >
            <img src={imgC} alt="Segmented Timeline Mobile" className="w-100 rounded-5 box-shadow" />

          </div>
          <div className="col-lg-6 col-md-10 col-sm-11">
            <div className="pt-9 pt-lg-0 pr-xs-10 pr-sm-5 pr-md-25 pr-lg-0 pl-xl-16">
              <h3 className="font-size-9 mb-10 mt-10">Desktop Chapter Selection</h3>
              <p
                className="font-size-5 mb-7 pr-xs-15 pr-sm-10 pr-xl-15"
                data-aos="fade-up"
                data-aos-duration={900}
              >Our handy chapter selection feature enables users to see and navigate your video's contents.</p>
              <p
                className="font-size-5 mb-7 pr-xs-15 pr-sm-10 pr-xl-15"
                data-aos="fade-up"
                data-aos-duration={900}
              >Highlighted image tabs with a small description to let the user know what section they are currently watching. As the user watches the video the non-highlighted tabs will become active.</p>
              <p className="font-size-5 mb-7 pr-xs-15 pr-sm-10 pr-xl-15" data-aos="fade-up"
                data-aos-duration={900}>The bookmark icon also indicates which section the user has bookmarked for reference.
              </p>
            </div>
          </div>
        </div>
        <div className="row justify-content-center mb-10 mt-lg-20">
          <div className="col-lg-6 col-md-10 col-sm-11">
            <div className="pt-9 pt-lg-0 pr-xs-10 pr-sm-5 pr-md-25 pr-lg-0 pl-xl-16">
              <h3 className="font-size-9 mb-10 mt-10">Mobile Chapter Selection</h3>
              <p className="font-size-5 mb-7 pr-xs-15 pr-sm-10 pr-xl-15" data-aos="fade-up"
                data-aos-duration={900}>On mobile, scrubbing back and forth on a small timeline dot with your finger can be difficult.</p>
              <p className="font-size-5 mb-7 pr-xs-15 pr-sm-10 pr-xl-15" data-aos="fade-up"
                data-aos-duration={900}>Now you can access the chapters from the toolbar. </p>
            </div>
          </div>
          <div
            className="col-xl-6 col-lg-6 col-md-10 col-sm-11 text-center"
            data-aos="fade-right"
            data-aos-duration={500}
          >
            <img src={imgD} alt="Chapter Selection Mobile" className="w-50 box-shadow rounded-5 mobilefull" />
            </div>          
        </div>
      </div>
    </div>
    </div>
  );
};

export default CTA;
