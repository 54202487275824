import React from "react";
import {Link} from "gatsby";
import PageWrapper from "../../../components/PageWrapper";
import Hero from "../../../sections/chapter-selection/Hero";
import Main from "../../../sections/chapter-selection/Main";
import OtherFeatures from "../../../sections/common/OtherFeatures";
import Wave from "../../../sections/common/Wave";
import CTA from "../../../sections/CTA/CTA";
import SEO from '../../../components/SEO';
import metaImg from '../../../assets/image/metaimg/chapterselection.jpg';

const PricingPage = () => {
  return (
    <>
      <PageWrapper        
        themeConfig={{
          headerClassName: "site-header--menu-left",
          headerFluid: false,

          footerStyle: "digma",
        }}>
        <SEO
          title="Get Instant gratification with our Chapter Selection Feature"
          description="Know where you want to go? Get there quicker with our chapter selection feature."
          image={metaImg}
        />            
         <div className="container pt-20 pt-md-16 pt-lg-20 ">
          <nav className="breadcrumb" aria-label="Breadcrumb">
            <ol className="breadcrumb__list">
              <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/">Home</Link>
                <span className="breadcrumb__separator" aria-hidden="true"> / </span>
              </li>
              <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/info">Info</Link>
                <span className="breadcrumb__separator" aria-hidden="true"> / </span>
              </li>
              <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/info/features">Features</Link>
                <span className="breadcrumb__separator" aria-hidden="true"> / </span>
              </li> 
              <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/info/features/chapter-selection">Chapter Selection</Link>
              </li>                            
              </ol>
           </nav>
        </div>             
        <Hero />
        <Wave color="#F7F9FC" /> 
        <Main className="grey" />
        <OtherFeatures />
        <CTA className="bg-images" />
      </PageWrapper>
    </>
  );
};
export default PricingPage;
